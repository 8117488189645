import { getPlainTextFromBlockContent } from 'gatsby-theme-sanity-evelan/src/lib/helpers';

const extractItem = (search, item) => {
  return Object.keys(item).some((key) => {
    if (item[key] !== null) {
      if (typeof item[key] === 'string' && item[key].toLowerCase().includes(search)) {
        return true;
      } else if (key === 'shortDescription' || key === 'description' || key === 'body') {
        if (getPlainTextFromBlockContent(item[key]).toLowerCase().includes(search)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
};
const deepSearch = (search, arr, compareId) => {
  return arr.filter((item) => {
    if (compareId) {
      if (item.category.id === compareId) {
        return extractItem(search, item);
      } else {
        return false;
      }
    } else {
      return extractItem(search, item);
    }
  });
};

export function FilterRenderer(categories, searchedValue, arr, callback) {
  let filteredArr = [];
  let searchedArr = [];

  arr.filter((item) => {
    return categories.map((checkedId) => {
      if (item.category.id === checkedId) {
        return filteredArr.push(item);
      } else {
        return false;
      }
    });
  });

  if (searchedValue) {
    if (filteredArr.length > 0) {
      searchedArr = deepSearch(searchedValue, filteredArr);
      callback(searchedArr);
    } else {
      if (categories.length === 0) {
        searchedArr = deepSearch(searchedValue, arr);
      } else {
        categories.filter((id) => {
          return (searchedArr = deepSearch(searchedValue, arr, id));
        });
      }
      if (searchedArr) {
        callback(searchedArr);
      } else {
        callback(filteredArr);
      }
    }
  } else {
    if (categories.length === 0) {
      callback(arr);
    } else {
      callback(filteredArr);
    }
  }
}
