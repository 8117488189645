import React from 'react';

import SearchIcon from '../../../images/icons/search-icon.svg';
import SearchResetIcon from '../../../images/icons/search-reset-icon.svg';

import styles from './search.module.scss';

export function Search({ searchLabel, searchedValue, setSearchedValue }) {
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setSearchedValue(value);
  };

  return (
    <div className={styles.wrapper}>
      <input
        type="text"
        className={styles.input}
        name="search"
        placeholder={searchLabel}
        onChange={(e) => handleSearch(e)}
        value={searchedValue}
      />
      {searchedValue.length > 0 ? (
        <img
          src={SearchResetIcon}
          className={styles.resetIcon}
          alt="Reset Search Icon"
          onClick={() => setSearchedValue('')}
        />
      ) : (
        <img src={SearchIcon} className={styles.icon} alt="Search Icon" />
      )}
    </div>
  );
}
