import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { FeaturedBlog } from '../components/blog/featured-blog/featured-blog';
import { StaticPage } from 'gatsby-theme-sanity-evelan/src/components/page/static-page';
import { chunk, formatDate } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { FilterHeader } from 'gatsby-theme-sanity-evelan/src/components/filter-header/filter-header';
import { FilterRenderer } from '../components/filter-header/filter-renderer';

import PostCard from '../components/blog/post-card/post-card';
import { PaginationButtons } from 'gatsby-theme-sanity-evelan/src/components/button/pagination-buttons';

import styles from './static-page-blog.module.scss';

function findFeaturedArticle(page = {}, posts = []) {
  // Filter for the "featuredBlogArticleSection" section to check if there is featured article
  const find = (section) => section._type === 'featuredBlogArticleSection';
  const pageFeatureArticle = page.body && Array.isArray(page.body) && page.body.find(find);

  const latestArticle = posts && posts.length > 0 && posts[0];

  // Fallback to the latest blog post, if the date of the section is empty
  return !!pageFeatureArticle && pageFeatureArticle.featuredArticle
    ? pageFeatureArticle.featuredArticle
    : latestArticle;
}

const StaticPageBlog = ({
  data: { page = {}, categories = [], companyInfo = {} },
  location,
  pageContext: { posts = [], currentPage: initialCurrentPage = 1 } = {},
}) => {
  // Search for the featured article

  const featuredArticle = findFeaturedArticle(page, posts);

  // filter the featured article from the posts
  const allPostsExceptFeatured =
    posts && posts.length > 0 ? posts.filter((p) => p.id !== featuredArticle._id && p.id !== featuredArticle.id) : [];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [currentPage, setCurrentPage] = useState(initialCurrentPage - 1);
  const [chunks, setChunks] = useState(chunk(allPostsExceptFeatured, process.env.GATSBY_POST_OVERVIEW_PAGE_SIZE));

  const dropdownOptions = categories.nodes.map((cat) => {
    return {
      id: cat.blogCategoryId,
      name: cat.name,
    };
  });

  const dropdownOnSelect = (e) => {
    const item = e.target.id;

    setCurrentPage(0);
    if (selectedOptions.includes(item)) {
      return setSelectedOptions(selectedOptions.filter((catId) => catId !== item));
    } else {
      return setSelectedOptions([...selectedOptions, item]);
    }
  };

  const dropdownOnReset = () => {
    setCurrentPage(0);
    return setSelectedOptions([]);
  };

  const blogPostChunk = (posts) => {
    setChunks(chunk(posts, process.env.GATSBY_POST_OVERVIEW_PAGE_SIZE));
  };

  useEffect(() => {
    FilterRenderer(selectedOptions, searchedValue, allPostsExceptFeatured, blogPostChunk);
    // PLEASE NOTE: since allPostsExceptFeatured will never changes on the client side, it's ok to not to check
    // for deep dependencies. If you put allPostsExceptFeatured as dependency, you will get the endless rerender!!!!!
  }, [selectedOptions.length, searchedValue, selectedOptions, allPostsExceptFeatured.length]);

  return (
    <StaticPage page={page}>
      <FeaturedBlog blog={featuredArticle} location={location} />
      <Container variant="thin" className={styles.blogsContainer}>
        <FilterHeader
          title="Artikel"
          dataLength={allPostsExceptFeatured.length}
          selectedOptions={selectedOptions}
          dropdownLabel="Kategorien"
          dropdownOptions={dropdownOptions}
          dropdownOnSelect={dropdownOnSelect}
          dropdownOnReset={dropdownOnReset}
          searchLabel="Suche"
          searchedValue={searchedValue}
          setSearchedValue={(value) => {
            setCurrentPage(0);
            setSearchedValue(value);
          }}
        />
        <div className={styles.blogPostWrapper}>
          {!!chunks[currentPage] ? (
            chunks[currentPage].map(({ id, mainImage, mainVideo, publishedAt, category, slug, title, body }) => {
              return (
                <PostCard
                  body={body}
                  key={id}
                  title={title}
                  mainImage={mainImage}
                  mainVideo={mainVideo}
                  publishedDate={formatDate(publishedAt)}
                  category={category.name}
                  color={category.color.hex}
                  slug={slug.current}
                />
              );
            })
          ) : (
            <h3>
              {selectedOptions.length || searchedValue.length
                ? 'Keine Beiträge gefunden, die Ihren Suchkriterien entsprechen'
                : 'Keine Beiträge gefunden'}
            </h3>
          )}
        </div>
        {!!chunks[currentPage] && (
          <PaginationButtons
            type="button"
            className={styles.navBtnContainer}
            showPrev={!!chunks[currentPage - 1]}
            showNext={!!chunks[currentPage + 1]}
            onPrev={() => {
              return currentPage !== 0 ? setCurrentPage(currentPage - 1) : false;
            }}
            onNext={() => {
              return currentPage !== chunks.length - 1 ? setCurrentPage(currentPage + 1) : false;
            }}
          />
        )}
      </Container>
    </StaticPage>
  );
};

export default StaticPageBlog;

export const query = graphql`
  query StaticPageBlog2 {
    page: sanityStaticPage(slug: { current: { eq: "blog" } }) {
      id: _id
      sections: _rawBody(resolveReferences: { maxDepth: 10 })
      mainImage: _rawMainImage(resolveReferences: { maxDepth: 3 })
      slug {
        current
      }
      description
      keywords
      title
      body {
        ... on SanityFeaturedBlogArticleSection {
          _type
          featuredArticle {
            _key
            _id
            authors: _rawAuthors(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            category {
              id: _id
              name
              color {
                hex
              }
            }
            mainImage: _rawMainImage(resolveReferences: { maxDepth: 5 })
            mainVideo {
              url
            }
            slug: _rawSlug(resolveReferences: { maxDepth: 10 })
            publishedAt
            title
          }
        }
      }
    }
    categories: allSanityBlogCategory {
      nodes {
        blogCategoryId: _id
        name
      }
    }
  }
`;
