import React from 'react';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BreakOutContainer } from 'gatsby-theme-sanity-evelan/src/components/container/break-out-container';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { H2 } from 'gatsby-theme-sanity-evelan/src/components/block-content/h2';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { ShareToolbar } from '../share-toolbar/share-toolbar';
import { formatDate } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { extractExcerptFromArticle } from 'gatsby-theme-sanity-evelan/src/components/blog/blog-utils';

import { Link } from 'gatsby';

import styles from './featured-blog.module.scss';

export const FeaturedBlog = ({ blog = {}, location }) => {
  const { mainImage, slug, publishedAt, body, title } = blog;

  const to = `/blog/${slug.current}`;

  const excerpt = extractExcerptFromArticle(body, 520);

  return (
    <div className={styles.overflow}>
      <Container variant="thin" className={styles.mainTitle}>
        <div className={styles.bannerTitle}>
          <h1>Blog</h1>
        </div>
      </Container>
      <Container variant="thin" className={styles.containerMain}>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <h5 className={styles.date}>{formatDate(publishedAt)}</h5>

            <H2 first={true} className={styles.title}>
              {title}
            </H2>
            <ShareToolbar
              text="TEILEN"
              url={`${location.origin}/blog/${slug.current}`}
              className={styles.shareToolbar}
            />
            <p className={styles.excerpt}>{excerpt}</p>
            <LinkButton className={styles.readMore} to={`/blog/${slug.current}`} variant="primary">
              Zum Beitrag
            </LinkButton>
          </div>
          {!!mainImage && (
            <div className={styles.imageWrapper}>
              <BreakOutContainer className={styles.imageContainer}>
                <Link
                  to={to}
                  state={{
                    autoPlay: true,
                  }}
                >
                  <PictureAsset image={mainImage} maxWidth={900} isFluid />
                  {/* {!!mainVideo && <img className={styles.play} src={PlayButton} alt="Video abspielen" />} */}
                </Link>
              </BreakOutContainer>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
