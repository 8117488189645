import React from 'react';
import clsx from 'clsx';

import { Dropdown } from '../dropdown/dropdown';
import { Search } from './search/search';

import styles from './filter-header.module.scss';

// Created this component to be re-use on both Course List and Blog Post List
// PROPS DETAILS:
// title -> Title of the Header (String)
// hightlight -> Highlighted Title (String)
// underline -> To show underline on title or not (Boolean)
// selectedOptions -> Array to be filled when selecting on dropdown (Array)
// dropdownLabel -> Label for dropdown (String)
// dropdownOptions -> List of options  (Array)
// dropdownOnSelect -> External function to be excuted on selecting (Func)
// dropdownOnReset -> External functions to be excuted when clearing the selected options (Func)
// searchLabel -> Label/Placeholder for search input (String)
// searchValue -> State variable for search (String)
// setSearchedValue -> State function to set the searched value (Func)

export function FilterHeader({
  title,
  dataLength,
  underline = false,
  selectedOptions,
  dropdownLabel,
  dropdownOptions,
  dropdownOnSelect,
  dropdownOnReset,
  searchLabel,
  searchedValue,
  setSearchedValue,
}) {
  return (
    <div className={styles.header}>
      <div className={styles.titleHolder}>
        <h5 className={clsx(styles.title, underline && styles.titleUnderline)}>
          {dataLength} {title}
        </h5>
      </div>
      <div className={styles.menuWrapper}>
        <Dropdown
          label={dropdownLabel}
          value={selectedOptions}
          options={dropdownOptions}
          onSelect={dropdownOnSelect}
          onReset={dropdownOnReset}
        />
        <Search searchLabel={searchLabel} searchedValue={searchedValue} setSearchedValue={setSearchedValue} />
      </div>
    </div>
  );
}
