import React from 'react';
import '../../styles/main.scss';
import { SEO } from 'gatsby-theme-sanity-evelan/src/components/seo';
import { LayoutContentData } from 'gatsby-theme-sanity-evelan/src/components/layout/layout-content-data';
import { AppContext } from 'gatsby-theme-sanity-evelan/src/contexts/AppContext';

export const StaticPage = ({ page = {}, children }) => {
  const { description, keywords, title } = page;

  return (
    <AppContext page={page}>
      <LayoutContentData>
        <SEO title={title} description={description} keywords={keywords} />
        {children}
      </LayoutContentData>
    </AppContext>
  );
};
